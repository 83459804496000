<template>
  <div>
    <p class="text-2xl">
      Afiliados
    </p>
    <v-row>
      <v-col cols="6">
        <v-card elevation="10">
          <v-card-title class="text-no-wrap">
            Login Compartilhado
          </v-card-title>
          <v-card-text class="d-flex">
            <v-text-field
              v-model="sharedLink"
              :append-icon="icons.mdiLink"
              class="sharedLink"
              outlined
              hide-details
              dense
              @click:append="copySharedLink"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card elevation="10">
          <v-card-title class="text-no-wrap">
            Informações
          </v-card-title>
          <v-card-text class="d-flex">
            <v-row>
              <v-col
                cols="12"
                md="6"
                class="d-flex align-center"
              >
                <v-avatar
                  size="44"
                  color="primary"
                  rounded
                  class="elevation-1"
                >
                  <v-icon
                    dark
                    color="white"
                    size="30"
                  >
                    {{ icons.mdiLink }}
                  </v-icon>
                </v-avatar>
                <div class="ms-3">
                  <p class="text-xs mb-0">
                    Qtd Campanhas
                  </p>
                  <h3 class="text-xl font-weight-semibold">
                    {{ totalCampaign }}
                  </h3>
                </div>
              </v-col>
              <!-- <v-col
                cols="12"
                md="4"
                class="d-flex align-center"
              >
                <v-avatar
                  size="44"
                  color="primary"
                  rounded
                  class="elevation-1"
                >
                  <v-icon
                    dark
                    color="white"
                    size="30"
                  >
                    {{ icons.mdiLink }}
                  </v-icon>
                </v-avatar>
                <div class="ms-3">
                  <p class="text-xs mb-0">
                    Comissão
                  </p>
                  <h3 class="text-xl font-weight-semibold">
                    Saiba mais...
                  </h3>
                </div>
              </v-col> -->
              <v-col
                cols="12"
                md="6"
                class="d-flex align-center"
              >
                <v-avatar
                  size="44"
                  color="primary"
                  rounded
                  class="elevation-1"
                >
                  <v-icon
                    dark
                    color="white"
                    size="30"
                  >
                    {{ icons.mdiLink }}
                  </v-icon>
                </v-avatar>
                <div class="ms-3">
                  <p class="text-xs mb-0">
                    Comissão
                  </p>
                  <h3 class="text-xl font-weight-semibold">
                    R$ {{ $money(totalComission, 4) }}
                  </h3>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card elevation="10">
          <v-card-text class="pa-3">
            <v-row>
              <v-col
                cols="12"
                md="2"
                sm="6"
              >
                <v-text-field
                  v-model="campaignId"
                  :append-icon="icons.mdiMagnify"
                  label="ID da Campanha"
                  hide-details
                  outlined
                  dense
                  @click:append="fetchReportServices"
                  @keypress.enter="fetchReportServices"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
                sm="6"
              >
                <v-text-field
                  v-model="campaignName"
                  :append-icon="icons.mdiMagnify"
                  label="Nome da Campanha"
                  hide-details
                  outlined
                  dense
                  @click:append="fetchReportServices"
                  @keypress.enter="fetchReportServices"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                sm="6"
              >
                <v-autocomplete
                  v-model="serviceId"
                  :items="services"
                  clearable
                  placeholder="Todos"
                  label="Serviço"
                  outlined
                  dense
                  hide-details
                  item-text="name"
                  item-value="id"
                  @change="fetchReportServices"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                sm="6"
              >
                <date
                  v-model="sendDateStart"
                  :type="'date'"
                  outlined
                  hide-details
                  label="Data Envio (Início)"
                  readonly
                  dense
                  @input="fetchReportServices"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                sm="6"
              >
                <date
                  v-model="sendDateEnd"
                  :type="'date'"
                  outlined
                  dense
                  hide-details
                  label="Data Envio (Fim)"
                  readonly
                  @input="fetchReportServices"
                />
              </v-col>
              <v-col
                v-if="isAdmin"
                cols="12"
                md="6"
                sm="6"
              >
                <v-autocomplete
                  v-model="affiliateId"
                  :disabled="!isAdmin"
                  :items="affiliates"
                  clearable
                  dense
                  placeholder="Todos"
                  label="Afiliado"
                  outlined
                  hide-details
                  item-text="value"
                  item-value="id"
                  @change="fetchReportServices"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                sm="6"
              >
                <v-autocomplete
                  v-model="userId"
                  :items="affiliateUsers"
                  clearable
                  dense
                  placeholder="Todos"
                  label="Cliente"
                  outlined
                  hide-details
                  item-text="value"
                  item-value="userId"
                  @change="fetchReportServices"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card
          flat
        >
          <v-data-table
            :headers="headers"
            :items="rows.records"
            item-key="id"
            class="table-rounded"
            disable-sort
            dense
          >
            <template #[`item.name`]="{ item }">
              <div class="d-flex flex-column">
                <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.name }}</span>
                <small>ID: <b>{{ item.id }}</b></small>
              </div>
            </template>
            <template #[`item.sendDate`]="{ item }">
              <div class="d-flex flex-column my-1">
                <span>
                  <v-icon
                    class="me-1"
                    size="18"
                  >
                    {{ icons.mdiCalendar }}
                  </v-icon>
                  {{ $day(item.sendDate).format('DD/MM/YYYY') }}
                </span>
                <span>
                  <v-icon
                    class="me-1"
                    size="18"
                  >
                    {{ icons.mdiClock }}
                  </v-icon>
                  {{ $day(item.sendDate).format('HH:mm') }}
                </span>
              </div>
            </template>
            <template #[`item.isPaused`]="{ item }">
              {{ resolveSituation(item.isPaused) }}
            </template>
            <template #[`item.validQuantity`]="{ item }">
              {{ $money(item.validQuantity, 0) }}
            </template>
            <template #[`item.affiliateCommission`]="{ item }">
              {{ $money(item.affiliateCommission, 0) }} %
            </template>
            <template #[`item.valueCommission`]="{ item }">
              R$ {{ $money(item.valueCommission, 4) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mdiLink, mdiCalendar, mdiClock } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { ROLE_TYPE } from '@/store/auth/mutations'

export default {
  setup() {
    const tenant = localStorage.getItem('tenant')
    const sharedLink = `https://app.strong.expert/signup?tenant=${tenant}`

    const campaignId = ref(null)
    const campaignName = ref(null)
    const serviceId = ref(null)
    const sendDateStart = ref(null)
    const sendDateEnd = ref(null)
    const affiliateId = ref(Number(localStorage.getItem('id')))
    const userId = ref(null)

    const services = ref([])
    const affiliateUsers = ref([])
    const affiliates = ref([])

    const totalCampaign = ref(0)
    const totalComission = ref(0)

    const headers = [
      { text: 'Campanha', value: 'name' },
      { text: 'Tipo', value: 'serviceName' },
      { text: 'Cliente', value: 'userName' },
      { text: 'Data Envio', value: 'sendDate' },
      { text: 'Quantidade', value: 'validQuantity', align: 'right' },
      { text: 'Comissão (%)', value: 'affiliateCommission', align: 'right' },
      { text: 'Valor (R$)', value: 'valueCommission', align: 'right' },
    ]

    return {
      sharedLink,
      headers,
      campaignId,
      campaignName,
      userId,
      serviceId,
      sendDateStart,
      sendDateEnd,
      affiliateId,
      affiliateUsers,
      affiliates,
      icons: {
        mdiLink,
        mdiCalendar,
        mdiClock,
      },
      services,
      totalCampaign,
      totalComission,
    }
  },

  computed: {
    ...mapState('affiliateReport', ['rows']),

    isAdmin() {
      const role = Number(Buffer.from(localStorage.getItem('role'), 'base64'))

      return role === ROLE_TYPE.admin
    },
  },

  watch: {
    rows: {
      handler() {
        if (this.rows.total) {
          this.totalCampaign = this.rows.total.records
          this.totalComission = this.rows.total.valueCommission
        }
      },
      immediate: true,
    },

    affiliateId: {
      handler() {
        this.getAffiliateUsers()
      },
      immediate: true,
    },
  },

  async created() {
    this.services = await this.findServices()

    if (this.isAdmin) {
      await this.fetchUsers()
    }

    await this.fetchReportServices()
  },

  methods: {
    ...mapActions('user', { findUsers: 'find' }),
    ...mapActions('services', { findServices: 'find' }),
    ...mapActions('affiliateReport', ['find', 'getUsersByAffiliate']),

    copySharedLink() {
      navigator.clipboard.writeText(this.sharedLink)
        .then(() => {
          this.$notify('URL copiada com sucesso', 'success')
        })
        .catch(err => {
          this.$notify('Erro ao copiar URL', 'error')
        })
    },

    async fetchReportServices() {
      const affiliateId = this.isAdmin ? this.affiliateId : localStorage.getItem('id')

      await this.find({
        affiliateId,
        campaignId: this.campaignId || null,
        campaignName: this.campaignName || null,
        serviceId: this.serviceId || null,
        sendDateStart: this.sendDateStart ? this.$day(this.sendDateStart, 'DD/MM/YYYY').format('YYYY-MM-DD 00:00:00') : null,
        sendDateEnd: this.sendDateEnd ? this.$day(this.sendDateEnd, 'DD/MM/YYYY').format('YYYY-MM-DD 23:59:59') : null,
        userId: this.userId || null,
      })
    },

    async fetchUsers() {
      const users = await this.findUsers()

      this.affiliates = users.filter(user => user.isAffiliate === true)
        .map(user => ({
          ...user,
          value: `${this.$cpfCnpj.toMask(user.document)} - ${user.name}`,
        }))
    },

    async getAffiliateUsers() {
      this.affiliateUsers = []

      if (this.affiliateId) {
        const users = await this.getUsersByAffiliate({
          affiliateId: this.isAdmin ? this.affiliateId : localStorage.getItem('id'),
        })

        if (users.length > 0) {
          this.affiliateUsers = users.map(user => ({
            ...user,
            value: `${user.userId} - ${user.userName} - (Saldo: R$ ${this.$money(user.availableLimit, 2)})`,
          }))
        }
      }
    },
  },
}
</script>
